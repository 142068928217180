.my_clients {
  .input_group {
    width: 405px;
    height: 48px;

    .input_box {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      background-color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}

.add_client_btn {
  width: 199px;
  height: 48px !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
}

.add_client_input {

  input,
  select,
  option,
  textarea,
  input::placeholder,
  textarea::placeholder,
  .react-tel-input {
    font-size: 16px !important;
  }

  textarea::placeholder {
    position: absolute;
    top: -22px;
    left: 10px;
  }
}

.client_card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 850px;
  height: 281px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  opacity: 1;

}

.client_name {
  font-size: 20px;
  font-weight: 500;
}

.client-p-tag-for-cpbottom {
  text-align: left;
  font: normal normal normal 18px/80px Roboto;
  letter-spacing: 0px;
  color: #535353;
}

.client-a-tag {
  text-align: left;
  text-decoration: underline !important;
  font: normal normal medium 18px/80px Roboto;
  letter-spacing: 0px;
  color: #1A295B;
  font-weight: 500;
}

.errorRegMessage {
  font-size: 12px;
  color: red;
}