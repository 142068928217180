.main-container {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 9;
  align-self: center;
  padding: 1% 0 1% 0;
  height: 85px;
  align-items: center;
  justify-content: space-between !important;
  -webkit-transition: all ease-out 0.2s;
  -moz-transition: all ease-out 0.2s;
  -o-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  .iconDivWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .moneyControlLogo {
    margin-top: -10px;
    width: 160px;
    cursor: default !important;
    margin-left: -15px;
  }

  .logo-icon {
    margin-top: -5px;
  }
  @media (max-width: 800px) {
    .iconDivWrap {
      align-items: center;
    }
    padding: 0;
    .moneyControlLogo {
      margin-top: -30px;
      width: 120px;
      margin-left: 0px;
    }
    .logo-icon {
      margin-top: 2px;
    }
  }
  .mobile-logo {
    height: 32px !important;
  }
  .full-screen-logo {
    height: 32px !important;
  }
  .logo-icon:hover {
    cursor: pointer;
  }
  .user-name {
    font-size: 1rem;
    &:hover {
      color: white;
    }
    @media (max-width: 800px) {
      font-size: 21px;
    }
  }
  .logo {
    color: #ffb657;

    margin: 5px 1rem 5px 0%;
    cursor: pointer;
    width: 8.333rem;
    height: 2.778rem;
  }
  .header_container {
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .breadcrumbs {
    color: white;
    display: flex;
    align-self: flex-start;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    margin-top: 0.7rem;
    letter-spacing: 0.54px;
    @media (max-width: 991px) {
      margin: 0px 5px;
    }
    @media (max-width: 800px) {
      display: none;
    }
  }
  .nav-links {
    display: flex;
    margin-top: 5px;
    .links {
      color: white;
      text-decoration: none;
      padding-right: 20px;
      padding-left: 0.5rem;
      font-size: 1rem;
      letter-spacing: 0.54px;
      opacity: 1;
      &:hover {
        color: #ffb657;
      }
      @media (max-width: 1700px) and (min-width: 800px) {
        font-size: 0.98rem;
      }
      @media (max-width: 991px) {
        margin: 10px 5px;
      }
      @media (max-width: 800px) {
        font-size: 21px;
        padding: 0.5rem 1rem;
      }
    }
    .highlight {
      color: #ffb657;
    }
  }
  .btns {
    display: flex;
    margin-left: auto;
    align-items: center !important;
    @media (max-width: 800px) {
      align-items: flex-start !important;
      width: 100%;
      margin-left: 17px !important;
    }

    .new_btn {
      background-color: transparent;
      border: 1px solid white;
      border-radius: 0.25rem;
      cursor: pointer;
      padding: 0.375rem 2rem;
      font-size: 1rem;
      @media (max-width: 800px) {
        font-size: 21px;
      }
      .a-btn {
        color: white;
        text-align: center;
        text-decoration: none;
      }
    }

    .header_profile {
      color: white;
      height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;
      margin-right: 50px;
      @media (max-width: 800px) {
        margin-left: 12px;
      }
      .header_profile_image {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .new_btn:nth-child(1) {
      display: flex;
      align-items: center;
      margin-right: 0.35rem;
      padding: 0;
      @media (max-width: 800px) {
        display: none;
      }
      .login-icon {
        width: 20px;
        height: 20px;

        margin-right: 10px;
      }
    }
    .new_btn:nth-child(2) {
      display: flex;
      align-items: center;
      margin-right: 0.35rem;
      @media (max-width: 800px) {
        margin-left: 10px;
      }
      .login-icon {
        width: 20px;
        height: 20px;

        margin-right: 10px;
        @media (max-width: 800px) {
          margin-left: -15px;
        }
      }
    }
    .new_btn:nth-child(3) {
      display: flex;
      align-items: center;
      margin-right: 0.35rem;
      .login-icon {
        margin-left: '20px';
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .new_btn:nth-child(4) {
      display: flex;
      align-items: center;
      @media (max-width: 800px) {
        display: none;
      }
      .login-icon {
        margin-left: '20px';
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .a-btn {
        color: white;
      }
    }
    .new_btn:nth-child(5) {
      display: flex;
      align-items: center;
      margin-right: 0.35rem;
      @media (max-width: 800px) {
        display: none;
      }
      .login-icon {
        margin-left: '20px';
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
.call_icon {
  display: none;
}
.whatsapp_icon {
  display: none;
}
.icon {
  display: none;
}

@media screen and (max-width: 800px) {
  .main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65px;
    width: 100% !important;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .logo {
      margin-right: auto;
      padding: 10px 30px;
      margin-left: 25px;
    }
    .mobile-logo {
      height: 25px !important;
      margin-top: -15px;
    }
    .full-screen-logo {
      height: 30px !important;
      margin-top: -15px;
      margin-left: 18px;
    }
    .nav-links {
      display: flex;
      margin: auto;
      flex-direction: column;
      margin-top: 50px;
      justify-content: space-between;
      .links {
        text-align: center;
      }
      .header-options {
        visibility: none;
        margin-bottom: 30px !important;
      }
    }
    .t-align{
      text-align: start !important;
    }
    .m_bt {
      margin-bottom: 0 !important;
    }
    .btns {
      flex-direction: column;
      margin: auto;
      justify-content: flex-start;
      align-items: 'center';
      margin-bottom: 30px;
      .new_btn {
        display: flex;
        margin-bottom: 30px;
        margin-left: 15px;
      }
      .header-options_dropdown {
        top: -70px !important;
        right: -160px !important;
        border-radius: 8px !important;
        width: 13rem !important;
        max-width: 14rem !important;
        max-height: 14rem !important;
        height: 14rem !important;
        display: flex;
        flex-direction: column;
      }
      .header_dropdown {
        right: 250px;
        .header-options_dropdown_item {
          padding: 0.2rem !important;
          color: #1a295b !important;
          font-weight: bold !important;
          text-align: start !important;
        }
      }
    }

    .call_icon {
      display: block;
      position: fixed;
      right: 60px;
      top: 18px;
      background-color: transparent;
      border: none;
      margin: 0 0.5vw;
    }
    .whatsapp_icon {
      display: block;
      position: fixed;
      right: 65px;
      top: 18px;
      background-color: transparent;
      border: none;
      margin: 0 0.5vw;
    }
    .icon {
      display: block;
      position: fixed;
      right: 20px;
      top: 18px;
      background-color: transparent;
      border: none;
      margin: 0 0.5vw;
    }
  }
  .header_container {
    display: flex;
    padding: 25px 0;
    flex-direction: column;
    height: auto;
    flex-basis: 0;
    width: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    position: relative;
    z-index: 9;
    &.toggle_open {
      justify-content: flex-start !important;
      align-items: flex-start !important;
      background-color: #1a295b !important;
    }
  }
}
.btns {
  position: relative;
  .header-options_dropdown {
    top: 0px !important;
    right: -100px !important;
    border-radius: 8px !important;
    width: 13rem !important;
    max-width: 14rem !important;
    max-height: 14rem !important;
    height: 14rem !important;
  }
  .header_dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 12rem;
    width: 12rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    user-select: none;
    

    .header-options_dropdown_item {
      padding: 0.88rem !important;
      color: #1a295b !important;
      font-weight: bold !important;
    }

    .dropdown_item {
      width: 100%;
      padding: 0.25rem 0 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      text-decoration: none;
      background-color: transparent;
      border: 0;
      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .dropdown_item:hover {
      background-color: #dddddd;
    }
    .submenu_title {
      display: flex;
      align-items: center;
    }
    .submenu_icon {
      padding-left: 10px;
      padding-bottom: 1px;
    }
    .linkedAccount {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      font-size: 1rem;
      width: 100%;
      text-align: start;
      list-style: none;
      background-color: #fff;
    }
    .dropdown_linkedaccount:hover .linkedAccount {
      display: block;
    }
  }
}
.linked-account-text {
  display: flex;
  align-items: center;
}

.bell-icon-button {
  @media (max-width: 800px) {
    margin-left: 105px;
  }
}
.active_100 {
  background-color: rgba(26, 41, 91, 0.4) !important;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}
.active_200 {
  background-color: rgba(26, 41, 91, 0.7) !important;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}

.active {
  background-color: rgba(26, 41, 91, 1) !important;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}

.phone-icon {
  width: 27px;
  height: 27px;
  transform: matrix(0.99, 0.1, -0.1, 0.99, 0, 0);

  /* UI Properties */

  // background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 1;
}

.sub_menu_class {
  height: 10rem;
  overflow-y: scroll;
}