.main {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../../assets/bg.webp');
  background-repeat: no-repeat;
  background-size: 100%;
}

/* @media (max-width: 800px) {
  .main {
    background-repeat: no-repeat;
    background-size: cover;
  }
} */

.login-icon {
  margin-left: '20px';
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.content_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 11.1rem;
  line-height: 1.5;
  font-weight: 700;
  max-height: 10rem;
}

.subtitle {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  color: white;
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 400;
}
.text {
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
}

.back_btn {
  margin-top: 3rem;
  width: 17rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 800;
  border: 0px;
  color: white;
  background-color: #ffb657;
}

@media (max-width: 800px) {
  .main {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .title {
    font-size: 8rem;
  }
  .subtitle {
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .text {
    font-size: 1rem;
  }
}
