.my_teams {
  .input_group {
    height: 48px;
    .input_box {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      background-color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}

.add_team_btn {
  width: 199px;
  height: 48px !important;
  border-radius: 5px !important;
  font-size: 18px !important;
}

.add_team_members_input {
  input,
  select,
  option,
  textarea,
  input::placeholder,
  textarea::placeholder,
  .react-tel-input {
    font-size: 16px !important;
  }
}

.members_card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-width: 19rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0509803922);
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  opacity: 1;
  font-size: unset !important;
}

.team_member_name {
  font-size: 21px;
  font-weight: 500;
  color: #000;
}

.phone_input_parent > .react-tel-input {
  display: flex;
  align-items: flex-end;
}
