.emailmodalBackground {
  position: fixed;
  background-color: #fff;
  z-index: 1;
}
.modalOtpContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  pointer-events: auto;
  outline: 0;
  padding: 10px;
  z-index: 1;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  color: red;
}
.modalBody {
  width: 100%;
  margin: 0 auto;
  overflow: hidden !important;
}
.title {
  h3 {
    padding-top: 13px;
    margin: 0;
    margin-bottom: 6.5px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
  }
}

.otp_text {
  font-size: 16px;
  @media screen and (max-width: 520px) {
    font-size: 13px;
  }
}

.subtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  color: #535353;
  letter-spacing: 0;
  p {
    margin-top: 5px;
    margin-bottom: 13px;
  }
}

.inputOtpContainer {
  min-height: 200px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.OtpWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 13px 26px;
  column-gap: 20px;
  input {
    width: 100%;
    margin: 15px 0;
    border: none;
    padding: 0.375rem 0.75rem;
    border-bottom: 1px solid #c1c1c1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  input:focus-visible {
    outline-offset: 0px;
  }
  input:focus {
    outline: none;
    background: none;
    background-color: none;
  }
  input:-internal-autofill-selected {
    background-image: none !important;
    background-color: #fff !important;
  }
}
.resendContainer {
  padding: 16px 0 0 !important;
  margin: 0;
  p {
    font-family: Roboto;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    color: red;
    margin-top: 0;
    margin-bottom: 13px;
  }
  button {
    font-size: 14px;
    font-weight: 700;
    color: #003cd5;
  }
}
.verifyButtonContainer {
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skiptext {
  padding: 13px 0;
  margin-right: 13px;
  p {
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
  }
  span {
    padding: 0 2px;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    color: #003cd5;
    opacity: 1;
  }
}
.btn {
  padding: 10px 39px;
  margin: 0;
  background: #ffb657 0 0 no-repeat padding-box;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.48px;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
  border: none;
}
@media screen and (max-width: 900px) {
  .modalOtpContainer {
    max-width: 500px;
  }
  .Wrapper {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 520px) {
  .modalOtpContainer {
    max-width: 400px;
  }
  .Wrapper {
    grid-template-columns: 1fr;
  }
}
