.RegModalBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
}

.modalContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  pointer-events: auto;
  outline: 0;
  padding: 13px;
  z-index: 1000;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #fff;
    border: 0px;
  }

  img {
    vertical-align: middle;
  }
}

.modalBody {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px 10px 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 520px) {
    padding: 0 15px 15px 15px;
  }

  @media (max-width: 479px) {
    padding: 0 15px 15px 15px;
  }
}

.title {
  h3 {
    padding: 13px 0px;
    margin: 0;
    margin-bottom: 6.5px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
  }
}

.inputContainer {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1700px) {
    min-height: auto;
  }
}

.Wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  padding: 13px;
  column-gap: 20px;
  row-gap: 10px;

  input, select {
    width: 100%;
    margin: 15px 0;
    border: none;
    padding: 0.375rem 0.75rem;
    border-bottom: 1px solid #c1c1c1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media (max-width: 767px) {
      margin: 10px 0;
    }
  }

  input:focus-visible {
    outline-offset: 0px;
  }

  input:focus, select:focus {
    outline: none;
    background: none;
    background-color: none;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background-color: #fff !important;
  }
}

.errorMessage {
  position: absolute;
  font-size: 13px;
  color: red;
  bottom: -15px;
}

.errorRegMessage {
  font-size: 13px;
  color: red;
  margin-top: -2.5px;
  font-weight: 400;
}

.react-tel-input .form-control {
  width: 100% !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #c1c1c1 !important;
  border-radius: 0 !important;
  height: 55px;

  @media (max-width: 1699px) {
    height: 48px;
  }
}

.react-tel-input .flag-dropdown {
  background: none !important;
  border: none !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}

.verifyButtonContainer {
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 13px 39px;
  margin: 0;
  background: #ffb657 0 0 no-repeat padding-box;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.48px;
  color: #000;
  text-transform: uppercase;
  opacity: 1;
  border: 1px solid transparent;

  :hover {
    background: #ffb657 0 0 no-repeat padding-box;
  }
}

.bottomContent {
  padding: 0px 0px 13px;
  margin: 0;

  p {
    font-family: Roboto;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    color: #000000;
    margin-top: 0;
    margin-bottom: 13px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    color: #1a295b;
  }
}

.tncBottom {
  padding: 13px 0;

  p {
    font-family: Roboto;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    color: #535353;
    margin-top: 0;
    margin-bottom: 13px;
  }

  span {
    font-size: 14px;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    color: #1a295b;
  }
}

.linked_checkbox {
  margin-bottom: 1rem;
  font-size: 14px;
  color: #535353;
}
.grid-item-4 {
  margin-top: 15.5px;
}

.serverErrorMessage {
  color: red;
  display: flex;
  justify-content: center;
}

.relationWithParentDropdown {
  background-color: white;
}

@media screen and (max-width: 900px) {
  .modalContainer {
    max-width: 500px;
    max-height: 800px;
  }

  .Wrapper {
    grid-template-columns: 1fr;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 520px) {
  .modalContainer {
    max-width: 400px;
    max-height: 800px;
  }

  .Wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 479px) {
  .modalContainer {
    max-width: 87%;
  }
}
