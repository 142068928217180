@import '../../../../../apps/landing-pages-web/styles/_variable.scss';

.enrollment_form {
  background: #fff;
  padding: 3ch;
  border-radius: 0.3rem;
}

.input_box {
  width: 100% !important;
  height: 40px !important;
  font-size: 16px !important;
}

.cp_wrapper {
  background-image: url('../../assets/background_img.png') !important;
  align-items: center;
  min-height: 100vh;
  padding-top: 95px;

  * {
    font-size: 16px;
  }
  h1 {
    font-size: 31.104px !important;
    font-weight: bold;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 6rem;
  }

  .container {
    @media only screen and (min-width: 1025px) {
      width: 800px;
    }
    @media only screen and (max-width: 800px) {
      width: 500px;
    }
    justify-content: center;
  }
}

.cp_heading {
  font-weight: 500 !important;
  font-size: 4.444rem !important;
  text-align: center;
  letter-spacing: 0;
  color: #fff;
  opacity: 1;
}

.cp_action_btn {
  background-color: $yellow !important;
  padding: 2rem !important;
  font-size: 2rem !important;
}

.action_btns {
  padding: 16px 50px;
  height: max-content !important;
  font-size: 1rem !important;
  min-width: 170px !important;
  background-color: $yellow;
  &:hover {
    background-color: $yellow;
  }
  @media only screen and (max-width: 800px) {
    width: 500px;
    padding: 22px 50px;
  }
}
