.main-container-flex {
  overflow-y: scroll !important;
}
.hBit_red_span {
  font-weight: 700;
  color: #ff0000;
}
.term-in-use {
  color: #000;
  font-weight: bold;
}
