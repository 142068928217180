.kyccontainer_card {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.058823529411764705);
  border-radius: 1rem;
  border: 1px solid #e2e2e2;
  opacity: 1;
  width: 100%;
}

.errorRegMessage {
  font-size: 16px;
  color: red;
}
.fileUpload {
  margin-top: 10px;
  border: 1.2px dashed #bbb;
  height: 50px;
  width: 160px;
  border-radius: 5px;
  cursor: pointer;
}
.hide {
  display: none;
}
.phoneInputWrapper {
  div > input {
    font-size: 16px !important;
    cursor: default !important;
  }
}

.datePicker_wrapper {
  div > div > input[type='text'] {
    font-size: 16px !important;
    width: 100% !important;
    outline: none !important;
    border-bottom: 1px solid rgb(165, 165, 165) !important;
    height: 45px;
  }
}

.snackWrapper {
  position: fixed;
  z-index: 2;
  top: 90px;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  flex-direction: row;
  justify-content: center;
  left: 50%;
  margin: 0 auto;
  display: flex;
  transform: translateX(-50%);
}
.snackContentWrapper {
  background-color: #ffb351;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  // border: 1px solid #cacaca;
  border-radius: 5px;
  margin: 0 0.5rem;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.snackContent {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto';
}
.snackButton {
  background: #1a295b;
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 5px 1.5rem;
  border: none !important;
  outline: none !important;
  border-radius: 3px;
  font-weight: 500;
  margin-left: 15px;
}

@media screen and (max-width: 768px) {
  .snackWrapper {
    display: block;
  }
  .snackContentWrapper {
    margin-bottom: 10px;
  }
  .cross-img {
    display: block;
  }
  .crossSign {
    margin-left: 30px;
  }
  .crossSignafterImage{
    margin-left: 40px;
  }
}
