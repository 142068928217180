/*Font variables */
$primary-font: "Roboto", sans-serif;
$primary: #1A295B;
/* Color variables */
$primary-70:#3d56aa;
$yellow:#ffb657;
$gray: #535353;
$gray-70: #F9F9F9;

$gradient-1: linear-gradient(180deg,#ffb558 2%,#ffb657 3%,#435cb3 64%,#003bd4 98%);
$gradient-2: linear-gradient(transparent,#000);
$gradient-3:linear-gradient(180deg,#1b2b5e,#5878e6);