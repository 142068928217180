.box-shadow-contsiner {
  box-shadow: inset 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.add_client_btn_overview {
  width: 140px;
  height: 36px !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
}