.container{
  background-color: white;
  bottom: 0;
  position: fixed;
  z-index: 100;
  right:2%;

}
.downloadtext{
  font-size: 14px;
  font-weight: bold;
  color:'#1a295b';
}