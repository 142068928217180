.bold-text{
  font-weight: bold;
  font-size: 11px;

}
.font-size-1{
  font-size: 11px;
}
.horizontal-scroll{
  // max-width: 800px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}